<template>
  <div class="mv-input-group d-flex flex-column my-3">
    <label
      class="form-label mv-input-group__label"
      :for="inputName"
    >
      {{ labelText }}
    </label>
    <input
      type="text"
      :name="inputName" :id="inputName"
      class="form-control form-control-sm mv-input-group__input"
    >
  </div>
</template>

<script>
export default {
  name: 'TextInput',
  props: {
    labelText: {
      type: String,
    },
    inputName: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  .mv-input-group {
    max-width: $input-group-max-width;
    width: $input-group-width;
    &__label {
      color: $input-group-label-text-color;
      font-size: $input-group-label-font-size;
      font-weight: $input-group-label-font-weight;
      order:1;
    }
    &__input {
      border-color:$input-group-input-border-color;
      border-radius: $input-group-input-border-radius;
      border-width: $input-group-input-border-width;
      color: $input-group-input-text-color;
      order:2;
      &:focus {
        border-color: $input-group-input-border-color-focus;
        box-shadow: none;
        outline: none;
      }
    }
    &--w-50 {
      width: 50%;
    }
    &--w-66 {
      width: 66%;
    }
    &--w-80 {
      width: 80%;
    }
    &--w-100 {
      width: 100%;
    }
  }
</style>
