<template>
  <tr class="mv-table__row">
    <td class="mv-table__cell">
      <label class="visually-hidden" :for="nameCol1">Name</label>
      <input class="mv-table__input" type="text" :id="nameCol1" :name="nameCol1">
    </td>
    <td class="mv-table__cell">
      <label class="visually-hidden" :for="nameCol2">Organization</label>
      <input class="mv-table__input" type="text" :id="nameCol2" :name="nameCol2">
    </td>
  </tr>
</template>

<script>
export default {
  name: 'FormTableRow',
  props: {
    // make these the headings and procedurally-generate the ids, maybe?
    nameCol1: {
      type: String,
      required: true,
    },
    nameCol2: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  .mv-table__row {
    td {
      padding: $form-table-cell-padding;
    }
    .mv-table__input {
      background: $form-table-input-bg;
      border: none;
      color: $mv_secondary_blue;
      width: $form-table-input-width;
      &:focus {
        background: $gray-200;
        outline: none;
      }
    }
  }
</style>
