<template>
  <div class="container-fluid mv-table-container mt-2 mb-4">
    <table class="table table-bordered mv-table">
      <thead class="mv-table__head">
        <tr class="mv-table__row">
          <th scope="col">{{ headingCol1 }}</th>
          <th scope="col">{{ headingCol2 }}</th>
        </tr>
      </thead>
      <tbody class="mv-table__body" id="mv-table-content">
        <FormTableRow nameCol1="name1" nameCol2="org1"></FormTableRow>
        <FormTableRow nameCol1="name2" nameCol2="org2"></FormTableRow>
        <FormTableRow nameCol1="name3" nameCol2="org3"></FormTableRow>
        <FormTableRow nameCol1="name4" nameCol2="org4"></FormTableRow>
      </tbody>
    </table>
    <div class="d-flex justify-content-end">
      <button class="btn btn-sm btn-info">+ Add Row</button>
    </div>
  </div>
</template>

<script>
import FormTableRow from '@/components/FormTableRow.vue';

export default {
  name: 'FormTable',
  components: {
    FormTableRow,
  },
  props: {
    headingCol1: {
      type: String,
      required: true,
      default: 'Name',
    },
    headingCol2: {
      type: String,
      required: true,
      default: 'Organization',
    },
  },
  // TODO: Row-adding functionality
  // setup() {}
};
</script>

<style lang="scss" scoped>
  .mv-table {
    border: $form-table-border;
    font-size: $form-table-font-size;
  }
  .mv-table thead {
    border-bottom: $form-table-border;
  }
  .mv-table th {
    color: $form-table-label-text-color;
  }
  .btn-info {
  &,
  &:link,
  &:visited {
    color: $btn-info-text-color;
    transition: $transition-default;
  }
  &:active,
  &:hover {
    background: $btn-info-bg-hover;
    color: $btn-info-text-color-hover;
    outline:none;
  }
  &:focus {
    background: $btn-info-bg-hover;
    color: $btn-info-text-color-hover;
    outline:none;
  }
}
</style>
