<template>
  <div>
    <div class="container">
      <h1 class="h2 m-2">Get Ready</h1>
      <!--<h1 class="h2 m-2">Get Ready {{ count }}</h1>-->
      <!--<button @click="increaseCounter">Increase Count</button>-->
      <FormTable headingCol1="Name" headingCol2="Organization"></FormTable>
      <div class="w-100 d-flex justify-content-evenly my-2">
        <RadioCheckbox id="choice1" value="Yes" groupName="choice-group"></RadioCheckbox>
        <RadioCheckbox id="choice2" value="Not yet, but we're working on it" groupName="choice-group"></RadioCheckbox>
        <RadioCheckbox id="choice3" value="Maybe" groupName="choice-group"></RadioCheckbox>
      </div>
      <div class="row w-100 d-flex justify-content-evenly my-2">
        <TextInput labelText="Who has been talking about this?" inputName="who-talking"></TextInput>
        <TextInput labelText="Who? What makes them effective?" inputName="question-effectiveness"></TextInput>
        <TextInput labelText="Is this a third question?" inputName="third-question"></TextInput>
      </div>
      <div class="row w-100 d-flex justify-content-evenly my-2">
        <TextareaInput labelText="Label" inputName="testarea"></TextareaInput>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import FormTable from '@/components/FormTable.vue';
import RadioCheckbox from '@/components/RadioCheckbox.vue';
import TextInput from '@/components/TextInput.vue';
import TextareaInput from '@/components/TextareaInput.vue';

export default {
  name: 'GetReady',
  components: {
    FormTable,
    RadioCheckbox,
    TextInput,
    TextareaInput,
  },
  setup() {
    const store = useStore();

    return {
      count: computed(() => store.state.readinessAssessment.testCounter),
      increaseCounter: () => store.dispatch('readinessAssessment/increaseCount'),
    };
  },
};
</script>
