<template>
  <div class="form-check form-check-inline">
    <div class="mv-checkbox">
      <input type="radio"
        class="form-check-input mv-checkbox__input"
        :id="id"
        :name="groupName"
        :value="value"
      >
      <label class="form-check-label mv-checkbox__label" :for="id">{{ value }}</label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RadioCheckbox',
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    groupName: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  .form-check-input[type="radio"] {
    background-image: none;
    border-color: $radio-input-border-color;
    border-radius: $radio-input-border-radius;
    border-width: $radio-input-border-width;
    box-shadow:none;
    margin-left:0;
    &:checked {
      background-image: $radio-input-checked-bg-img;
      box-shadow:none;
      outline:none;
    }
  }
  // is autoprefixer included? if not, it needs to be
  .mv-checkbox {
    align-items:center;
    display:flex;
    flex-direction:column;
    justify-content:center;
    text-align:center;
    &__label {
      color: $radio-input-label-text-color;
      font-size: $radio-input-label-font-size;
      font-weight: $input-group-label-font-weight;
      order:1;
    }
    &__input {
      margin-left:0;
      order:2;
    }
  }
</style>
